@tailwind base;
@tailwind components;


:root {
  --p: 185 53% 35%;
  --pf: 185 82% 25%;
  --pc: 0 0% 100%;
  --s: 43 100% 62%;
  --sf: 43 81% 52%;
  --sc: 33 14% 37%;
  --a: 177 85% 39%;
  --af: 177 87% 33%;
  --ac: 0 0% 100%;
  --n: 205 83% 28%;
  --nf: 206 84% 17%;
  --nc: 0 0% 100%;
  --b1: 0 0% 100%;
  --b2: 180 15% 94%;
  --b3: 173 27% 87%;
  --bc: 206 84% 17%;
  --in: 217 100% 40%;
  --su: 177 85% 39%;
  --wa: 27 98% 63%;
  --er: 349 81% 58%;

  --rounded-box: 1rem; /* border-radius for cards and other big elements */
  --rounded-btn: 0.5rem; /* border-radius for buttons and similar elements */
  --rounded-badge: 9999px; /* border-radius for badge and other small elements */

  --animation-btn: 0.25s; /* bounce animation time for button */
  --animation-input: .4s; /* bounce animation time for checkbox, toggle, etc */

  --padding-card: 2rem; /* default card-body padding */

  --btn-text-case: none; /* default text case for buttons */
  --navbar-padding: .5rem; /* default padding for navbar */
  --border-btn: 1px; /* default border size for button */
  --focus-ring: 2px; /* focus ring size for button and inputs */
  --focus-ring-offset: 2px; /* focus ring offset size for button and inputs */
}
@layer base {
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button,
  input[type='number'] {
    -webkit-appearance: none;
    /* margin: 0; */
    -moz-appearance: textfield !important;
  }
}
h1 {
  @apply font-bold mt-0;
  font-size: 22px;
  font-family: 'Roboto Slab', serif;
}
h2 {
  @apply font-normal mt-0;
  font-size: 20px;
  font-family: 'Roboto Slab', serif;
}
h3 {
  @apply font-normal mt-0;
  font-size: 18px;
  font-family: 'Roboto Slab', serif;
}
h4 {
  @apply font-medium mt-0;
  font-size: 16px;
  font-family: 'Roboto Slab', serif;
}
h5 {
  @apply font-normal mt-0;
  font-size: 14px;
  font-family: 'Roboto Slab', serif;
}
h6 {
  @apply font-normal mt-0;
  font-size: 12px;
  font-family: 'Roboto', serif;
}

p {
  font-size: 14px;
  font-family: 'Roboto', serif;
}

.input, .select, .textarea {
  @apply shadow-md;
}

.btn:not(.btn-link, .btn-ghost) {
  @apply shadow-lg;
}

.bg-primary .badge,
.bg-primary .label-text
{
  color: hsl(var(--nc));
}

.bg-primary .checkbox-mark {
  border: 1px solid hsl(var(--nc))
}

.header--layout__small {
  @apply flex items-center justify-between w-full p-3 h-16 bg-primary text-primary-content;
}

.bg-pattern {
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: bottom;
}

.swal2-confirm .swal2-styled {
  background-color: hsl(var(--s));
}

.downshift-options {
  @apply rounded-md input absolute z-10 left-0 right-0 top-16 shadow-lg 
}

.downshift-options-focus {
  @apply bg-gray-200
}

.downshift-options-selected {
  @apply font-bold
}

.invalid-feedback {
  @apply mt-1 text-xs text-red-500
}
.invalid-tooltip {
  @apply absolute z-10 hidden w-4 font-normal leading-normal text-white rounded p-2 bg-red-700
}
.is-invalid {
  @apply border border-red-500
}
.is-invalid:focus {
  @apply border border-red-500 ring
}

.txt {
  @apply text-base font-light leading-relaxed mt-0 mb-4
}
.txt-sm {
  @apply font-normal leading-normal mt-0 mb-4 
}
.txt-lg {
  @apply text-lg font-light leading-relaxed mt-6 mb-4
}

.card {
  @apply rounded-xl
}

.card.white {
  @apply bg-white shadow-lg
}

.scrolling-wrapper {
  @apply flex flex-nowrap	overflow-x-auto
}
  /* Hide scrollbar for IE, Edge and Firefox
  scrollbar-width: none;  /* Firefox */  

  /* Hide scrollbar for Chrome, Safari and Opera */

.scrolling-wrapper::-webkit-scrollbar {
  display: none;
}

.scrolling-wrapper .card {
  justify-content: center;
  flex: 0 0 auto;
  align-items: center;
  display: flex;
  flex-direction: column;
}


/* customs styles */

.sidebar--user-nfo {
  @apply flex border-b pb-5 mb-5 mt-12;
}

.avatar {
  @apply rounded-full bg-base-100 overflow-hidden flex items-center text-center
}

.input {
  @apply text-base-content font-bold;
}

input::placeholder {
  @apply font-normal;
}

.select {
  @apply text-gray-600;
}

.menu {
  @apply w-full;
}

.menu li>a {
  padding: 0.5rem;
}

.stats {
  @apply block h-full;
}

.stats .graphic {
  @apply flex flex-col justify-center items-center;
  height: calc(100% - 40px);
}


@tailwind utilities;